@import url("https://rsms.me/inter/inter.css");
@import "primeflex/primeflex.scss";

// @import "./styles/primeflex";
@import "./styles/base";
@import "./styles/app";

@import "./styles/prime/prime";

@import "./styles/pages/clients-page";
@import "./styles/pages/treatment-page";
@import "./styles/pages/feedback-page";
@import "./styles/pages/landing-page";
@import "./styles/pages/settings-page";

@import "./styles/components/spinner";
@import "./styles/components/area_video";
@import "./styles/components/custom-questions";
@import "./styles/components/clock";
@import "./styles/components/start-new-session";
@import "./styles/components/user-settings";
@import "./styles/components/current-treatment-note-review-editor";
@import "./styles/components/clients-page-previous-note-review";

@import "./styles/components/area-component";
@import "./styles/components/conditions-select";

@import "./styles/components/note-viewers";
@import "./styles/components/rom";

@import "./styles/misc";
// @import "./styles/pages";
