.App {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.AuthOutlet {
  height: 100%;
  display: grid;
  grid-template-rows: 55px 1fr min-content;
  width: 100%;
}

.AuthOutlet__Main {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  // To keep with the existing native app appearance for tablets,
  // and to stop resizing r3f canvas when orientation changes,
  // make under the app header invisible to ensure landscape use.
  @media screen and (orientation: portrait) {
    visibility: hidden;
  }
  @media screen and (min-width: 1240px) {
    max-width: 1400px;
    justify-self: center;
  }
  @media screen and (min-width: 1400px) {
    max-width: 1600px;
    justify-self: center;
  }
}

.App__Footer,
.AuthOutlet__Footer {
  background-color: var(--teal-100);
  width: 100%;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
}

.AuthOutlet__Header {
  display: grid;
  grid-template-columns: min-content auto 250px;
  height: 100%;

  background-color: var(--teal-600);
  width: 100%;
  padding: 0.25rem;
  @media screen and (orientation: portrait) {
    visibility: hidden;
  }
  .TreatmentToolbar {
    @media screen and (orientation: portrait) {
      visibility: hidden;
    }
  }
  div > div.AppHeader__InnerText {
    display: flex;
    @media all and (max-width: 1023px) {
      display: none;
    }
  }
}
