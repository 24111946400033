// @import "primeflex/primeflex.scss";
.p-panel-header .p-panel-icons:empty {
  height: 2em;
}

.p-selectbutton .p-button.p-highlight {
  color: yellow;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #0f766e;
  color: yellow;
  border-color: #0f766e;
}
.p-button.p-component {
  font-size: 0.875rem;
  padding: 0.65625rem 1.09375rem;
  &.Selected {
    // box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px yellow, 0 1px 2px 0 black;
    background: var(--teal-700);
    color: yellow;
  }
}
.p-button .p-button-icon {
  font-size: 0.875rem;
}

.p-panel.p-component.CustomPanel {
  .p-panel-header {
    .p-panel-title {
      font-size: 16px;
    }
  }
  display: flex;
  flex-direction: column;
  height: 100%;

  .p-toggleable-content {
    height: 100%;
    .p-panel-content {
      height: 100%;
    }
  }
}
.p-panel.p-component.CustomPanel .p-toggleable-content .p-panel-content {
  padding: 0.25rem;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem var(--teal-600);
}
.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background: #ffffff;
  border-color: #adb5bd;
  color: #6c757d;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: var(--teal-600);
  border-color: var(--teal-600);
  color: yellow;
}
.p-tabview.p-component {
  height: 100%;
}
.p-sidebar .p-sidebar-header {
  padding: 0;
  background-color: var(--teal-600);
  height: 55px;
}
.p-sidebar .p-sidebar-content {
  padding: 0.75rem;
}
.HeaderSidebar.p-sidebar-left.p-sidebar {
  width: 220px;
}

.p-panel.p-component.CustomPanel .p-toggleable-content .p-panel-content {
  padding: 0.25rem;
}
.p-sidebar .p-sidebar-header {
  padding: 0;
  background-color: var(--teal-600);
  height: 55px;
}
.p-sidebar .p-sidebar-content {
  padding: 0.75rem;
}
.HeaderSidebar.p-sidebar-left.p-sidebar {
  width: 220px;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #99f6e4;
  // background-color: #99f6e4;
}
li.p-listbox-item.p-highlight {
  font-weight: bold;
}
.ql-editor {
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: var(--teal-600);
  color: yellow;
  box-shadow: none;
  border: none;
  outline: none;
}
// li.p-treenode:nth-of-type(odd) {
//   .p-treenode-content {
//     background-color: var(--surface-100);
//   }
//   .p-treenode-children:nth-of-type(even) {
//     .p-treenode-content {
//       background-color: white;
//     }
//     li.p-treenode:nth-of-type(even) {
//       .p-treenode-content {
//         background-color: var(--surface-100);
//       }
//       .p-treenode-children:nth-of-type(odd) {
//         .p-treenode-content {
//           background-color: white;
//         }#TreeConditionsSelect > ul > li:nth-child(1)
//       }
//     }
//   }#TreeConditionsSelect > ul > li:nth-child(1) > div > button
// }
#TreeConditionsSelect {
  span.p-treenode-label {
    font-weight: 600;
  }
}
li.p-treenode:nth-of-type(odd) {
  .p-treenode-content {
    background-color: var(--surface-100);
  }
  li.p-treenode:nth-child(1) {
    .p-treenode-content {
      background-color: white;
    }
    li.p-treenode:nth-of-type(odd) {
      .p-treenode-content {
        background-color: var(--surface-100);
      }
      li.p-treenode:nth-child(1) {
        .p-treenode-content {
          background-color: white;
        }
      }
    }
  }
}

.p-treenode-content.p-treenode-selectable.p-highlight {
  .p-treenode-label {
    text-decoration: underline;
  }
}

// #TreeConditionsSelect > ul > li:nth-child(1) {
//   background-color: white;
// }
// li.p-treenode:nth-of-type(odd) {
//   .p-treenode-content {
//     background-color: var(--surface-100);
//   }
// }
.p-treenode-content {
  button.p-tree-toggler {
    height: 3rem;
    width: 3rem;
  }
}
#TreeConditionsSelect {
  button.p-tree-toggler.p-link {
    height: 2.5rem;
    width: 2.5rem;
  }
}
li.p-treenode.p-treenode-leaf:nth-of-type(odd) {
  background-color: var(--surface-100);
}
li.p-listbox-item:nth-of-type(odd) {
  background-color: var(--surface-100);
  border-bottom: 1px solid black;
}
// .p-editor-container.ReadOnlyEditor {
// }
// .p-editor-toolbar.ReadOnlyEditor {
// }
// .p-editor-content.ReadOnlyEditor {
// }
.ql-snow .ql-editor img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  background-color: #7fffe52f;
}

.ReviewNotes__Img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  background-color: #7fffe52f;
}

.DashNav .p-menu-list {
  display: flex;
}
.ClientDD.p-dropdown {
  font-size: 14px;
}
.ClientDD .p-dropdown-label.p-inputtext {
  font-size: 0.75rem;
  padding: 0.5rem;
}
.ClientHeaderDropdown .p-dropdown-label.p-inputtext {
  padding: 0.5rem;
  color: var(--text-color);
  font-weight: 600;
}

.ClientHeaderDropdown {
  width: 100%;
}

.ClientHeader.p-inputtext {
  padding: 0.5rem;
  width: auto;
}
.p-inputtext.Clock {
  padding: 0.5rem;
  color: var(--teal-700);
  font-family: SourceCodePro, monospace;
  font-weight: 800;
}
.p-editor-container .p-editor-content.ql-snow {
  border: none;
}
span.p-treenode-label {
  line-height: 1.75;
  font-weight: 500;
}

span.p-treenode-label.p-hightlight {
  font-weight: 600;
}
.p-hightlight {
  box-shadow: inset 0 0 0 0.15rem #99f6e4;
}
.p-treenode-selectable {
  color: black;
}
.p-inputtextarea.p-inputtext.p-component {
  line-height: 1.5;
}
#TreeConditionsSelect .p-tree-toggler {
  background: var(--teal-600);
  color: white;
}
#TreeConditionsSelect2 .p-tree-toggler {
  background: var(--teal-600);
  color: white;
}
// #TreeConditionsSelect .p-treenode-content.p-treenode-selectable {
//   font-weight: 900;
// }
// .ql-editor {
//   height: auto;
//   overflow-y: visible;
// }
// .ReadOnlyEditor {
//   height: 100%;
// }
#SideBarMenuButton {
  background-color: transparent;
  color: white;
  border: none;
  box-shadow: none;
  outline: none;
  &.p-button-icon.p-c.pi.pi-bars {
    font-size: 1.25rem;
  }
}
.p-datepicker table td > span.p-highlight {
  border: 1px solid var(--teal-700);
}
.DatePickerInputTextArea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  // border: 1px solid #ced4da;
  border-top-color: rgb(206, 212, 218);
  border-top-style: solid;
  border-top-width: 1px;

  border-bottom-color: rgb(206, 212, 218);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  // border-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.DatePickerIconBtn {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
// .DatePickTextContainer {
// }
.CustomDropdownIcon .p-dropdown-trigger {
  background-color: var(--teal-600);
  color: white;
}
.CustomDropdownIcon .p-dropdown.p-dropdown-clear-icon {
  right: 4rem;
}
.CustomDropdownIcon .p-dropdown-clear-icon {
  right: 4rem;
}
.p-datepicker {
  transform: none;
}
.UserSettings__HeaderText {
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  font-size: smaller;
  padding: 1rem;
}
.SessionDatetime__Calander {
  height: 500px;
}
.p-datepicker.p-component.p-datepicker-inline {
  display: grid;
  grid-template-rows: 1fr auto min-content;
  width: 500px;
}
#SideBarMenuButton .p-button-icon.p-c.pi.pi-bars {
  font-size: 1.25rem;
}

.p-tabview.p-component {
  display: flex;
  flex-direction: column;
}
.p-tabview-panel {
  height: 100%;
}
.p-tabview-panels {
  height: 100%;
}
