.AreaOptions {
  display: grid;
  grid-template-rows: 450px min-content;
  row-gap: 2px;
  width: 400px;
  //   padding: 1rem;
}
.TestAreaGrid {
  display: grid;
  grid-template-rows: min-content min-content auto min-content;
  row-gap: 2px;
  height: 100%;
  width: 100%;
  padding: 1rem;
}
