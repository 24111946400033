.NoteViewer__Background {
  background: #495057;
  padding: 1rem;
  border-radius: 6px;
}

.NoteViewer__Page {
  height: auto;
  min-height: 842px;
  width: 595px;
  background-color: white;
}

.NoteViewer__Main {
  max-height: 65vh;
}

.MockReadOnlyEditor__InnerContent {
  font-family: Inter, sans-serif;
  font-size: 15px;
  word-wrap: break-word;
  height: 100%;
  line-height: 1.42;
  outline: 0;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
}

.NotesDocument__BackgroundContainer {
  background: #495057;
  padding: 2rem;
  border-radius: 8px;
  height: 70vh;
  overflow-y: auto;

  // border-bottom-left-radius: 6px;
  // border-bottom-right-radius: 6px;
  // color: #495057;
}
.NotesDocument__Image {
  background-color: rgba(127, 255, 229, 0.184);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-width: 100%;
}
.SessionDatetimePicker__Container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
// .NotesDocument__PageContainer {
// }

.NotesDocument__Page {
  // height: clamp(500px, 842px, 90vh);
  background-color: white;
  width: 650px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 842px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  line-height: 1.42;
  outline: 0;
  overflow-y: auto;
  font-size: 15px;
  word-wrap: break-word;
  padding: 12px 15px;

  // h2,
  // h3,
  // h4,
  // h5,
  // h6,
  // p,
  // span,
  // div {
  //   margin: 0;
  //   color: black;
  // }
  // p {
  //   color: black;
  // }
}
