.ConditionsSelectDialogContent {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: clamp(400px, 500px, 45vw) clamp(400px, 500px, 45vw);
  column-gap: 2px;

  &__Item {
    height: clamp(400px, 600px, 65vh);
  }
}
.ConditionsSelectTree {
  overflow-y: auto;
}

#TreeConditionsSelect {
  height: clamp(400px, 600px, 65vh);
}
.SelectedConditionItem {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  width: 100%;
}
.SelectedConditionItemContent {
  display: grid;
  grid-template-rows: 1fr auto;
}
.ConditionsSelect__SymptomItem {
  display: grid;

  grid-template-rows: 1fr 120px min-content;
}
/* ---- Conditions ---- */
.ConditionsDialog__MainGrid {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: clamp(400px, 500px, 45vw) clamp(400px, 500px, 45vw);
  grid-template-rows: 500px;
  column-gap: 2px;
}
.TestConditionItem {
  display: grid;
  grid-template-rows: 55px 400px min-content;
  height: 100%;
  width: 100%;
}
.TestConditionItem__Body {
  display: grid;
  grid-template-rows: 2fr 1fr;
  height: 100%;
  width: 100%;
  align-items: center;
}
.SymptomGrid {
  display: grid;
  grid-template-rows: 2fr 1fr;
  height: 100%;
  width: 100%;
  max-height: 100%;

  row-gap: 0.25rem;
}
.TestGrid {
  display: grid;
  grid-template-rows: 2fr 1fr auto;
  height: 100%;
  width: 100%;
  row-gap: 0.25rem;
}
.ObservationRadioContainer {
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 100%;
}
