.ClockText {
  color: var(--teal-700);

  font-family: "Roboto Mono", monospace;
  font-weight: 700;
  padding: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  // -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 6px;

  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}
