.konvajs-content {
  touch-action: none;
}
.TopRoundBorder {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.BottomRoundBorder {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.A4 {
  width: 595px;
  height: 842px;
}
.Wrapper {
  width: clamp(16rem, 90vw, 70rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.ContinuousA4 {
  width: 595px;
  height: auto;
  min-height: 842px;
}
.MimicA4 {
  width: 650px;
  height: 90vh;
  padding: 1rem;
}
.box {
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
}
@keyframes my-anim {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
.my-anim {
  animation: my-anim 300ms ease-in 1 both;
}

@keyframes my-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes my-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.my-fadein {
  animation: my-fadein 150ms linear both;
}

.my-fadeout {
  animation: my-fadeout 150ms linear both;
}
.ReadOnlyEditor {
  height: auto;
  min-height: 842px;
  width: 595px;
}

.SettingsLabel__Text {
  font-weight: 600;
  font-size: 1rem;
}
.NoteTabSettings__ContainerGrid {
  display: grid;
  grid-template-rows: 50px min-content 1fr 45px;
  justify-items: center;
}
.QuestionAnswerOption {
  &:hover {
    cursor: pointer;
  }
}
.PopoverTraiangle {
  left: calc(var(--overlayArrowLeft, 0) + 1.25rem);
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 8px;
  margin-left: -8px;
  bottom: auto;
  top: 100%;
  border-top-color: #ffffff;
}
