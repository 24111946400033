.FeedbackPage {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  // justify-content: center;
  // align-items: center;
}

.FeedbackPage__Grid {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 5fr 2fr;
}
.FeedbackPage__Container__Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // justify-self: center;
  height: clamp(400px, 80%, 750px);
  width: clamp(300px, 60%, 800px);
}
.FeedbackPage__Container {
  height: 100%;
  width: 100%;
  gap: 1rem;

  // align-items: center;
  // padding: 1rem;
  // display: grid;
  // grid-template-rows: auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // justify-self: center;
}
.FeedbackPage__PrivateFeedback {
  width: clamp(400px, 60vw, 700px);
  // height: clamp(450px, 60vh, 800px);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
