// .AreaVideo__DialogClassName {
// }
.AreaVideo__DialogContentClassName.p-dialog-content {
  padding: 0;
  margin: 0;
  border-radius: 0;
  overflow-y: visible;
}
.AreaVideo__DialogContentClassName.p-dialog-content:last-of-type {
  border-radius: 0;
}
.AreaVideo__Container {
  width: 80vw;
  position: relative;
  padding-top: 56.25%;
}
.AreaVideo__YouTubePlayer {
  position: absolute;
  top: 0;
  left: 0;
}
