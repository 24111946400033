.TreatmentPage {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 7fr 3fr;
  column-gap: 0.5rem;
  @media screen and (orientation: portrait) {
    visibility: hidden;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: auto 345px;
  }
  .TreatmentPage__ContainerTwo {
    height: 100%;
    width: 100%;
    min-width: 345px;
    grid-row-gap: 0.5rem;
    display: grid;
    grid-template-rows: auto 1fr min-content min-content;

    row-gap: 0.5rem;
  }
}
.TreatmentPage__ContainerOne {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  @media screen and (orientation: portrait) {
    visibility: hidden;
  }
}
.TreatmentNotes {
  display: grid;
  grid-template-rows: 55px 1fr 120px;
  height: 100%;
  width: 100%;
}
.NoteButtonsGrid {
  display: grid;
  padding: 0.75rem 0.5rem;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
  height: 100%;
}
.TreatmentNoteInputTextarea {
  width: 100%;
  height: clamp(180px, 300px, 40vh);
  resize: none;
  padding-bottom: env(safe-area-inset-bottom);
  @media only screen and (max-height: 768px) {
    height: 180px;
  }
}
.TreatmentNotesFooter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.VideoPlayer__Wrapper {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
.VideoPlayer__Component {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1;
}
