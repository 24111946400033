.CurrentTreatmentNoteReviewEditor {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 870px;
  max-height: 80vh;
  width: 100%;

  &__OverflowY {
    overflow-y: auto;
  }
  &__BackgroundContainer {
    background: #495057;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  &__PageContainer {
    background-color: white;
    border-radius: 8px;
  }
  &__Page {
    background-color: white;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 842px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    line-height: 1.42;
    outline: 0;
    overflow-y: auto;
    font-size: 15px;
    word-wrap: break-word;
    padding: 12px 15px;
    width: 650px;
  }
  &__ImagesPage {
    border-radius: 8px;
    background-color: white;

    min-height: 842px;
    padding: 12px 15px;
    width: 650px;
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 2rem;
  }
}
// .TreatmentNoteEditorClassName {
// }
// #TreatmentNoteEditorID {
// }

/* ---- CurrentTreatmentNote ---- */
.MockReadOnlyEditor__BackgroundContainer {
  background: #495057;
  // border-bottom-left-radius: 6px;
  // border-bottom-right-radius: 6px;
  // color: #495057;
}
.MockReadOnlyEditor__Image {
  background-color: rgba(127, 255, 229, 0.184);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-width: 100%;
}

.MockReadOnlyEditor__Page {
  // height: clamp(500px, 842px, 90vh);
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 842px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  line-height: 1.42;
  outline: 0;
  overflow-y: auto;
  font-size: 15px;
  word-wrap: break-word;
  padding: 12px 15px;

  // h2,
  // h3,
  // h4,
  // h5,
  // h6,
  // p,
  // span,
  // div {
  //   margin: 0;
  //   color: black;
  // }
  // p {
  //   color: black;
  // }
}
