/* 
    These body, #root, .App settings ensures that: 
    1. The web app mimics the appearance of a native app when used on tablets
    2. The keyboard overlay (on touch devices) does not disrupt the broader layout. Ex. Clicking (focusing) into a text input will crush/resize the canvas component.
*/

html {
  // Resize PrimeReact's default font size
  font-size: 14px;
  // Speed Up Any Delay To Click
  touch-action: manipulation;
}

body {
  margin: 0;
  padding: 0;
  background: linear-gradient(340deg, #f8f9fa 15%, #b7fcf1 80%);
}

#root {
  padding: 0;
  margin: 0;
  display: grid;
  align-items: center;
  height: 100vh;

  width: 100%;
}

* {
  font-family: "Inter", sans-serif;
  @supports (font-variation-settings: normal) {
    font-family: "Inter var", sans-serif;
  }
}

// Some (obscure/old) settings on iPad can possibly impact r3f (+ prime?)
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}
