.LandingPage {
  height: 100%;

  width: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: 340px 340px;
  grid-template-rows: 480px;
  padding-bottom: 10%;
  column-gap: 0.25rem;
  div > .p-tabview-nav-container {
    border-radius: 8px;
    .p-tabview-nav-content {
      border-radius: 8px;
    }
  }
}
.LandingPage__TitleGrid {
  display: grid;
  width: 100%;
  height: 100%;
  background-color: white;
  grid-template-rows: 3fr 2fr;
}
.ProHealthNotesLogoImage {
  max-width: 100%;
  margin: auto;
  img {
    border-radius: 6px;
  }
}
