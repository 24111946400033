.ClientsPage {
  display: grid;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  gap: 1rem;
}

.ClientsPage__Card {
  overflow-y: auto;
  width: 430px;
  height: clamp(500px, 620px, 90%);
  max-height: 100%;
}

.Clients__Grid {
  display: grid;
  height: 100%;
  width: 80%;
}

.Clients {
  padding: 1rem;
  height: 100%;
  width: 100%;
  row-gap: 1rem;

  grid-template-rows: auto 450px;
  display: grid;
}
