.UserSettings__CreateModify {
  display: grid;
  height: 100%;
  grid-template-rows: 50px 1fr 45px;
}
.UserSettings__CreateModifyEditQB {
  display: grid;
  height: 100%;
  grid-template-rows: 50px min-content 1fr 45px;
}

.UserSettings__Header,
.UserSettings__Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.UserSettings__Main {
  .ModifyGrid {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
}
.UserSettings__CustomQ {
  display: grid;
  height: 100%;

  grid-template-rows: 50px min-content 1fr 45px;
}
.UserSettings__CustomQModify {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 50px min-content auto 45px;
}
.UserSettingsTabView .p-tabview-panels {
  overflow-y: auto;
}
// .UserSettingsTabPanel {
// }
