.RangeOfMovementPanel {
  width: clamp(720px, 95vw, 900px);
}
.RangeOfMovementPanelContent {
  height: clamp(450px, 650px, 65vh);
  display: grid;
  grid-template-rows: auto min-content;
  row-gap: 0.5rem;

  &__Footer {
    display: grid;
    grid-template-columns: auto min-content;
  }
}

.JointMotionItem {
  grid-column-gap: 0.25rem;
  -webkit-column-gap: 0.25rem;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;

  height: 50px;
  width: 100%;
}
.RangeOfMovementOptionsGrid {
  display: grid;
  grid-template-columns: min-content min-content min-content auto;
  column-gap: 0.25rem;
  max-height: 100%;
}

.MaxH70 {
  max-height: 70%;
}

.JointMotionItem {
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  height: 50px;
  column-gap: 0.25rem;
}
.RangeOfMovementPanelContent__Footer {
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto auto;
  height: 50px;
}
.ConditionsSelect__Item {
  display: grid;
  grid-template-rows: 1fr auto auto;
  row-gap: 0.25rem;
}
.ROMPANEL__COMPONENT {
  width: clamp(720px, 85vw, 1000px);
}
.ARROW {
  &::after {
    bottom: 100%;
    left: calc(var(--overlayArrowLeft, 0) + 1.25rem);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 8px;
    margin-left: 8px;
    bottom: auto;
    top: 100%;
    border-top-color: #ffffff;
    border-bottom-color: transparent;
  }
  &::before {
    bottom: 100%;
    left: calc(var(--overlayArrowLeft, 0) + 1.25rem);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 8px;
    margin-left: 8px;
    bottom: auto;
    top: 100%;
    border-top-color: #ffffff;
    border-bottom-color: transparent;
  }
}
