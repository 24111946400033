.CustomQuestionOverlayDiv {
  width: 480px;
  height: 480px;
}
#CustomQuestionListBox {
  .p-listbox-list-wrapper {
  }
}
.QuestionAnswerOption:nth-child(even) {
  background-color: var(--surface-100);
  border-right: 1px solid var(--surface-border);
  border-left: 1px solid var(--surface-border);
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  &:hover {
    background-color: var(--teal-100);
  }
}
.QuestionAnswerOption:nth-child(odd) {
  border: 1px solid var(--surface-border);
  &:hover {
    background-color: var(--teal-100);
  }
}
.QuestionAnswerOption:last-child {
  border-bottom: 1px solid var(--surface-border);
}
