.SettingsPage__PanelTabs {
  width: 90%;

  max-width: 1000px;
  overflow-y: auto;
  // @media screen and (max-height: 767px) {
  //   max-height: 480px;
  // }
}
.SettingsPage {
  height: 100%;
  width: 100%;
}
// .p-tabview.p-component.UserSettingsTabView {
//   & .p-tabview-panel {
//     height: "600px";
//   }
// }
// .p-tabview.p-component.UserSettingsTabView {
//   .p-tabview-panels .p-tabview-panel > div {
//     height: "600px";
//   }
// }

// .UserSettings {
//   height: 600px;
// }

.p-tabview-panels.UserSettingsTabPanelContent {
  height: 600px;
  background-color: var(--surface-200);
}
