.ClientsPagePreviousNoteReview {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  width: 100%;
  max-height: 65vh;

  &__OverflowY {
    overflow-y: auto;
  }
  &__BackgroundContainer {
    background: #495057;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  &__PageContainer {
    background-color: white;
    border-radius: 8px;
  }
  &__Page {
    background-color: white;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 842px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    line-height: 1.42;
    outline: 0;
    overflow-y: auto;
    font-size: 15px;
    word-wrap: break-word;
    padding: 12px 15px;
    width: 650px;
  }
  &__ImagesPage {
    border-radius: 8px;
    background-color: white;

    min-height: 842px;
    padding: 12px 15px;
    width: 650px;
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 2rem;
  }
}
