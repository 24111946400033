.StartNewSession__DialogDiv {
  width: 80%;
  height: 80%;
  max-width: 700px;
  max-height: 670px;
}
.NewSessionGrid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 0.25rem;
}
.SelectStartPanels .p-tabview-panels {
  border: 1px solid var(--surface-border);
  border-top: none;
}
